import LineGradient from '../components/LineGradient';
import { motion } from 'framer-motion';
import { getTranslate } from '../../localization';

const container = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.2 },
  },
};

const projectVariant = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

const Skill = ({ title, description }) => {
  return (
    <motion.div variants={projectVariant}>
      <div className="w-20 m-auto">
        <img src={`../assets/${title}.png`} alt={title} />
      </div>
      <div>
        <p className="text-2xl text-center font-playfair">{description}</p>
      </div>
    </motion.div>
  );
};

const MySkills = ({ setSelectedPage }) => {
  const translate = getTranslate();
  return (
    <section id="skills" className="pt-20 pb-20">
      {/* HEADER AND IMAGE SECTION */}
      <motion.div
        className="md:w-2/4 mx-auto text-center pb-20"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <p className="font-playfair font-semibold text-4xl mb-5 pt-6">
          {translate.my} <span className="text-red">{translate.s}</span>
        </p>
        <LineGradient width="w-3/3" />
      </motion.div>
      {/* SKILLS */}
      <div className="flex justify-center">
        <div className="sm:grid sm:grid-rows-3 gap-20">
          {/* ROW 1 */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            className="sm:grid sm:grid-cols-4 gap-20"
            onViewportEnter={() => setSelectedPage('skills')}
            viewport={{ once: true, amount: 0.5 }}
            variants={container}
          >
            <Skill title="html" description="HTML" />
            <Skill title="css" description="CSS" />
            <Skill title="js" description="JavaScript" />
            <Skill title="react" description="React" />
          </motion.div>
          {/* ROW 2 */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            className="sm:grid sm:grid-cols-4 gap-20"
            viewport={{ once: true, amount: 0.5 }}
            variants={container}
          >
            <Skill title="api" description="RestFul API" />
            <Skill title="nodejs" description="Node.js" />
            <Skill title="ui" description="Material UI" />
            <Skill title="tailwind" description="Tailwind CSS" />
          </motion.div>
          {/* ROW 3 */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            className="sm:grid sm:grid-cols-3 gap-20"
            viewport={{ once: true, amount: 0.5 }}
            variants={container}
          >
            <Skill title="redux" description="Redux" />
            <Skill title="githubb" description="git" />
            <Skill title="typescript" description="TypeScript" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default MySkills;
