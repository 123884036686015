/* eslint-disable eqeqeq */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Box, Typography, Button, Tabs, Tab } from '@mui/material';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { shades } from '../../../theme';
import { addToCart } from '../../state';
import { useParams } from 'react-router-dom';
import Item from '../../components/Item';
import { getTranslate, getDirection } from '../../../localization';

const ItemDetails = () => {
  const translate = getTranslate();
  const dispatch = useDispatch();
  const { itemId } = useParams();
  const [value, setValue] = useState('description');
  const [count, setCount] = useState(1);
  const itemssen = useSelector((state) => state.cart.itemsen);
  const itemssfa = useSelector((state) => state.cart.itemsfa);
  const itemen = itemssen.filter((item) => item.id == itemId);
  const itemfa = itemssfa.filter((item) => item.id == itemId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return getDirection() === 'ltr' ? (
    <Box width="80%" m="80px auto">
      <Box display="flex" flexWrap="wrap" columnGap="40px">
        {/* IMAGES */}
        <Box flex="1 1 40%" mb="40px">
          <img
            alt={itemen[0].name}
            width="100%"
            height="100%"
            src={itemen[0].image}
            style={{ objectFit: 'contain' }}
          />
        </Box>

        {/* ACTIONS */}
        <Box flex="1 1 50%" mb="40px">
          <Box display="flex" justifyContent="space-between">
            <Box>{translate.homeItem}</Box>
            <Box>{translate.prevNext}</Box>
          </Box>

          <Box m="65px 0 25px 0">
            <Typography variant="h3">{itemen[0].name}</Typography>
            <Typography>
              {itemen[0].price} {translate.price}
            </Typography>
            <Typography sx={{ mt: '20px' }}>
              {itemen[0].shortDescription}
            </Typography>
          </Box>

          {/* COUNT AND BUTTON */}
          <Box display="flex" alignItems="center" minHeight="50px">
            <Box
              display="flex"
              alignItems="center"
              border={`1.5px solid ${shades.neutral[300]}`}
              mr="20px"
              p="2px 5px"
            >
              <IconButton onClick={() => setCount(Math.max(count - 1, 1))}>
                <RemoveIcon />
              </IconButton>
              <Typography sx={{ p: '0 5px' }}>{count}</Typography>
              <IconButton onClick={() => setCount(count + 1)}>
                <AddIcon />
              </IconButton>
            </Box>
            <Button
              sx={{
                backgroundColor: '#222222',
                color: 'white',
                borderRadius: 0,
                minWidth: '150px',
                padding: '10px 40px',
              }}
              onClick={() =>
                dispatch(addToCart({ item: { ...itemen[0], count } }))
              }
            >
              {translate.addToCart}
            </Button>
          </Box>

          <Box>
            <Box m="20px 0 5px 0" display="flex">
              <FavoriteBorderOutlinedIcon />
              <Typography sx={{ ml: '5px' }}>{translate.addToWish}</Typography>
            </Box>
            <Typography>
              {translate.categories} {itemen[0].category}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* INFORMATION */}
      <Box m="20px 0">
        <Tabs value={value} onChange={handleChange}>
          <Tab label={translate.description} value="description" />
          <Tab label={translate.reviews} value="reviews" />
        </Tabs>
      </Box>
      <Box display="flex" flexWrap="wrap" gap="15px">
        {value === 'description' && <div>{itemen[0].longDescription}</div>}
        {value === 'reviews' && <div>{translate.reviews}</div>}
      </Box>

      {/* RELATED ITEMS */}
      <Box mt="50px" width="100%">
        <Typography variant="h3" fontWeight="bold">
          {translate.related}
        </Typography>
        <Box
          mt="20px"
          display="flex"
          flexWrap="wrap"
          columnGap="1.33%"
          justifyContent="space-between"
        >
          {itemssen.slice(0, 4).map((item, i) => (
            <Item key={`${item.name}-${i}`} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box width="80%" m="80px auto">
      <Box display="flex" flexWrap="wrap" columnGap="40px">
        {/* IMAGES */}
        <Box flex="1 1 40%" mb="40px">
          <img
            alt={itemfa[0].name}
            width="100%"
            height="100%"
            src={itemfa[0].image}
            style={{ objectFit: 'contain' }}
          />
        </Box>

        {/* ACTIONS */}
        <Box flex="1 1 50%" mb="40px">
          <Box display="flex" justifyContent="space-between">
            <Box>{translate.homeItem}</Box>
            <Box>{translate.prevNext}</Box>
          </Box>

          <Box m="65px 0 25px 0">
            <Typography variant="h3">{itemfa[0].name}</Typography>
            <Typography>
              {itemfa[0].price} {translate.price}
            </Typography>
            <Typography sx={{ mt: '20px' }}>
              {itemfa[0].shortDescription}
            </Typography>
          </Box>

          {/* COUNT AND BUTTON */}
          <Box display="flex" alignItems="center" minHeight="50px">
            <Box
              display="flex"
              alignItems="center"
              border={`1.5px solid ${shades.neutral[300]}`}
              mr="20px"
              p="2px 5px"
            >
              <IconButton onClick={() => setCount(Math.max(count - 1, 1))}>
                <RemoveIcon />
              </IconButton>
              <Typography sx={{ p: '0 5px' }}>{count}</Typography>
              <IconButton onClick={() => setCount(count + 1)}>
                <AddIcon />
              </IconButton>
            </Box>
            <Button
              sx={{
                backgroundColor: '#222222',
                color: 'white',
                borderRadius: 0,
                minWidth: '150px',
                padding: '10px 40px',
              }}
              onClick={() =>
                dispatch(addToCart({ item: { ...itemfa[0], count } }))
              }
            >
              {translate.addToCart}
            </Button>
          </Box>

          <Box>
            <Box m="20px 0 5px 0" display="flex">
              <FavoriteBorderOutlinedIcon />
              <Typography sx={{ ml: '5px' }}>{translate.addToWish}</Typography>
            </Box>
            <Typography>
              {translate.categories} {itemfa[0].category}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* INFORMATION */}
      <Box m="20px 0">
        <Tabs value={value} onChange={handleChange}>
          <Tab label={translate.description} value="description" />
          <Tab label={translate.reviews} value="reviews" />
        </Tabs>
      </Box>
      <Box display="flex" flexWrap="wrap" gap="15px">
        {value === 'description' && <div>{itemfa[0].longDescription}</div>}
        {value === 'reviews' && <div>{translate.reviews}</div>}
      </Box>

      {/* RELATED ITEMS */}
      <Box mt="50px" width="100%">
        <Typography variant="h3" fontWeight="bold">
          {translate.related}
        </Typography>
        <Box
          mt="20px"
          display="flex"
          flexWrap="wrap"
          columnGap="1.33%"
          justifyContent="space-between"
        >
          {itemssfa.slice(0, 4).map((item, i) => (
            <Item key={`${item.name}-${i}`} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ItemDetails;
