// eslint-disable-next-line import/no-anonymous-default-export
export default {
  lorem:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  aboutUs: 'About Us',
  careers: 'Careers',
  ourStore: 'Our Store',
  terms: 'Terms & Conditions',
  privacy: 'Privacy Policy',
  care: 'Customer Care',
  help: 'Frequently asked questions',
  track: 'Track Your Order',
  return: 'Returns & Refunds',
  bug: 'Bug Report',
  contactUs: 'Contact Us',
  address: '50 north Whatever Blvd, Washington, DC 10501',
  email: 'Email',
  shoppingBag: 'SHOPPING BAG',
  price: '$',
  subtotal: 'SUBTOTAL',
  checkout: 'CHECKOUT',
  addToCart: 'ADD TO CART',
  newItems: '-- NEW ITEMS',
  summerSale: 'Summer Sale',
  discoverMore: 'Discover More',
  ourFeatured: 'Our Featured Products',
  all: 'ALL',
  newArrivals: 'NEW ARRIVALS',
  bestSellers: 'BEST SELLERS',
  topRated: 'TOP RATED',
  subscribe: 'Subscribe To Our Newsletter',
  coupon: 'and recive $20 coupon for your first order when you checkout',
  enterEmail: 'Enter Email',
  sub: 'Subscribe',
  homeItem: 'Home / Item',
  prevNext: 'Prev / Next',
  addToWish: 'ADD TO WISHLIST',
  categories: 'CATEGORIES:',
  description: 'DESCRIPTION',
  reviews: 'REVIEWS',
  related: 'Related Products',
  billing: 'Billing',
  payment: 'Payment',
  back: 'Back',
  next: 'Next',
  placeOrder: 'Place Order',
  firstName: 'First Name',
  lastName: 'Last Name',
  country: 'Country',
  street: 'Street Address',
  street2: 'Street Address 2 (optional)',
  city: 'City',
  state: 'State',
  zip: 'Zip Code',
  contact: 'Contact Info',
  phone: 'Phone Number',
  billingInfo: 'Billing Information',
  sameForShip: 'Same for Shipping Address',
  shipAdd: 'Shipping Information',
  ali: 'Ali',
  nayebi: 'Nayebi',
  squaddes:
    'I am a professional Front-End Developer with diverse skills (such as coding, UI/UX design, and SEO) I have the ability to design websites in different formats.',
  contactus: 'Contact Me',
  home: 'Home',
  skill: 'Skills',
  projects: 'Projects',
  contactp: 'Contact',
  co: 'CONTACT ME',
  to: 'TO GET STARTED',
  name: 'NAME',
  em: 'EMAIL',
  message: 'MESSAGE',
  sendm: 'SEND ME A MESSAGE',
  p: 'PRO',
  j: 'JECTS',
  project:
    'here you can see some of my projects, hover on each one to see which libraries and modules have been used to create them for example this portfolio have been created with react, tailwindcss, framer motion and react-hook-forms. you can also click on them to enter each project.',
  project1: 'Bilingual ecommerce application',
  mem: 'MEMBERS',
  my: 'MY',
  s: 'SKILLS',
  pn: 'Phone Number: 09216057609',
  emmail: 'Email: elderekko@gmail.com',
  project2: 'TypeScript sample project',
};
