const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-9 gap-7">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/ali-nayebi-2a7780290"
        target="_blank"
        rel="noreferrer"
      >
        <img className="w-7" alt="linkedin-link" src="../assets/linkedin.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://github.com/Ali-Nayebi"
        target="_blank"
        rel="noreferrer"
      >
        <img className="w-7" alt="github-link" src="../assets/github.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://t.me/ElderEkko"
        target="_blank"
        rel="noreferrer"
      >
        <img className="w-7" alt="facebook-link" src="../assets/telegram.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://instagram.com/ali_.nb?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="w-7"
          alt="instagram-link"
          src="../assets/instagram.png"
        />
      </a>
    </div>
  );
};

export default SocialMediaIcons;
