// eslint-disable-next-line import/no-anonymous-default-export
export default {
  lorem:
    'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. ',
  aboutUs: 'درباره ما',
  careers: 'مشاغل',
  ourStore: 'فروشگاه ما',
  terms: 'شرایط و ضوابط',
  privacy: 'سیاست حفظ حریم خصوصی',
  care: 'خدمات مشتریان',
  help: 'پاسخ به پرسش های متداول',
  track: 'پیگیری سفارش',
  return: 'رویه های بازگرداندن کالا',
  bug: 'گزارش باگ',
  contactUs: 'تماس با ما',
  address: 'یه جایی تو میرداماد پلاک نمی دونم چند واحد 10',
  email: 'ایمیل',
  shoppingBag: 'سبد خرید',
  price: 'تومان',
  subtotal: 'مجموع',
  checkout: 'تکمیل خرید',
  addToCart: 'افزودن به سبد خرید',
  newItems: 'کالا های جدید--',
  summerSale: 'جشنواره تابستانی',
  discoverMore: '-بیشتر-',
  ourFeatured: 'محصولات ویژه ما',
  all: 'همه',
  newArrivals: 'تازه ها',
  bestSellers: 'پر فروش ها',
  topRated: 'برترین ها',
  subscribe: 'در خبرنامه ما مشترک شوید',
  coupon: 'و روی اولین سفارش خود یک کوپن 200,000 تومانی دریافت کنید',
  enterEmail: 'ایمیل خود را وارد کنید',
  sub: 'ثبت نام',
  homeItem: 'خانه / محصول',
  prevNext: 'قبلی / بعدی',
  addToWish: 'افزودن به علاقه مندی ها',
  categories: 'دسته بندی ها:',
  description: 'توضیحات',
  reviews: 'نظرات',
  related: 'محصولات مرتبط',
  billing: 'صورتحساب',
  payment: 'پرداخت',
  back: 'بازگشت',
  next: 'ادامه',
  placeOrder: 'ثبت سفارش',
  firstName: 'نام',
  lastName: 'نام خانوادگی',
  country: 'کشور',
  street: 'خیابان',
  street2: 'خیابان فرعی (اختیاری)',
  city: 'شهر',
  state: 'استان',
  zip: 'کد پستی',
  contact: 'اطلاعات تماس',
  phone: 'شماره موبایل',
  billingInfo: 'اطلاعات صورتحساب',
  sameForShip: 'همین مقادیر برای آدرس ارسال',
  shipAdd: 'آدرس ارسال',
  ali: 'علی',
  nayebi: 'نایبی',
  squaddes:
    'من یک طراح فرانت هستم، که توانایی طراحی انواع سایت در قالب های مختلف را دارم.',
  contactus: 'تماس با من',
  home: 'خانه',
  skill: 'مهارت ها',
  projects: 'نمونه کار',
  contactp: 'تماس با من',
  co: 'برای شروع',
  to: 'به من پیام دهید',
  name: 'نام',
  em: 'ایمیل',
  message: 'پیام',
  sendm: 'ارسال پیام',
  p: 'نمونه  ',
  j: 'کار ها',
  project:
    'اینجا می توانید چند نمونه کار از من ببینید، با قرار دادن موس خود روی هر کدام میتوانید کتابخانه ها و ماژول های استفاده شده در آنها را ببینید به عنوان مثال این پورتفولیو با react, tailwindcss, framer motion, react-hook-forms نوشته شده. همچنین با کلیک کردن روی هر کدام می توانید به آن پروژه وارد شوید.  ',
  project1: 'آنلاین شاپ دو زبانه',
  mem: 'اعضا',
  my: 'مهارت',
  s: 'های من',
  pn: 'شماره تماس: 09216057609',
  emmail: 'ایمیل: elderekko@gmail.com',
  project2: 'پروژه تایپ اسکریپت',
};
